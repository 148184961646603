import dynamic from 'next/dynamic';
import { Flex } from '@chakra-ui/react';

const Footer = dynamic(() => import('../Footer'));
const Nav = dynamic(() => import('../Nav'));
const SideElements = dynamic(() => import('../SideElements'));
const ArticlesCarousel = dynamic(() => import('../ArticlesCarousel'));
const Subscribe = dynamic(() => import('../Subscribe'));
const DownloadSheetForm = dynamic(() => import('../DownloadSheetForm'));

const Layout = ({
  children,
  currentArticle = null,
  global,
  withCarousel = false,
  withSideElements = false,
  withSubscribe = false,
  withDownloadProduct = false,
}) => (
  <>
    <Nav />
    <>
      {withSideElements ? (
        <Flex flexDirection="row" justifyContent="center" my={20}>
          {children}
          <SideElements global={global} />
        </Flex>
      ) : (
        <Flex direction="column">{children}</Flex>
      )}
    </>
    {withCarousel && <ArticlesCarousel article={currentArticle} />}
    {withSubscribe && <Subscribe />}
    {withDownloadProduct && <DownloadSheetForm />}
    <Footer />
  </>
);

export default Layout;
